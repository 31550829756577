<template>
  <div class="things">
    <things-head v-if="head_Content" :val="head_Content"></things-head>

<!--      导航-->
      <div class="navigation" style="position: relative;">
            <share :name="solutionInfo.name" :val="solutionInfo" type="dig"/>
            <a :class="{ active: solutionTabIndex === 'rol0' }" @click="setFloorNavMountClick('rol0')">方案介绍</a>
            <a v-if="usebool" :class="{ active: solutionTabIndex === 'rol1' }" @click="setFloorNavMountClick('rol1')">方案架构</a>
            <a :class="{ active: solutionTabIndex === 'rol2' }" @click="setFloorNavMountClick('rol2')">方案优势</a>
            <a :class="{ active: solutionTabIndex === 'rol3' }" @click="setFloorNavMountClick('rol3')">应用价值</a>
      </div>

<!--    方案介绍-->
    <things-int id="rol0" :val="int_content"></things-int>



    <things-arc id="rol1" v-if="usebool" :val="arc_content"></things-arc>



      <div id="rol2" class="superiority box">
        <div>
            <p>方案优势</p>
            <advantage :val="superiority_content" />
        </div>
      </div>

      <div id="rol3" class="value box">
          <p>应用价值</p>
<!--          <jiazhi :val="value_content" class="content" />-->

        <div class="content" :class="value_content.length>3? 'strLayout':'cenLayout'">
          <div v-for="item in value_content" :key="item.id"  class="change">
            <div>{{item.name}}</div>
            <div>{{item.valueDiscription}}</div>
          </div>
        </div>


      </div>




    <sub-bottom></sub-bottom>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import advantage from "./components/advantage.vue";
import jiazhi from "./components/jiazhi.vue";
import things_head from "@/page/solution/components/things_head";
import things_int from "@/page/solution/components/things_int";
import things_arc from "@/page/solution/components/things_arc";
import { getSolutionInfo } from "@/api/solution";
import sub_bottom from "@/page/solution/components/sub_bottom";
import share from "@/page/solution/components/share"

export default {
  name: "internet_things",
  components:{
    jiazhi,
    advantage,
    "things-head":things_head,
    "things-int":things_int,
    "things-arc":things_arc,
    "sub-bottom":sub_bottom,
    share
  },
  computed: {
    ...mapGetters(["isLogin"]),
    id: function () {
      return this.$route.query.id;
    },

  },
  data(){
    return{
      solutionTabIndex: 'rol0',

      superiority_content:"",

      valId:'',

      usebool:false,
      solutionInfo: "",
      value_content:'',
      head_Content:'',
      int_content:'',
      arc_content:'',
      imgList:{
        img3:require("@/assets/img/things5.png"),
      },

      backgroundCon:{
        backgroundImage: 'url('+require("@/assets/img/things6.png")+')',
      },

      isMask:false,

    }
  },
  mounted() {
   this.loadGetSolutionInfo();
  },
  methods: {
    // 方案详情
    loadGetSolutionInfo() {
      getSolutionInfo(this.id).then((res) => {
        this.solutionInfo = res;
        let createDate = new Date(res.createTime);
        let upDate = new Date('2022-07-21 00:00:00');
        createDate = createDate.getTime();
        upDate = upDate.getTime();
        if(createDate > upDate){
          this.isMask = true
        }

        this.usebool=res.enableFrameworkImg


        this.head_Content={
          name:res.name,
          text:res.solSlogan,
          backgroundImg:res.solutionLogoImg,
          maskbool:this.isMask,
        }

        this.int_content={
          name:'方案介绍',
          text: res.solutionDiscription,
          img:res.solutionImg,
          backgroundImg:{
            backgroundImage: 'url('+require("@/assets/img/things2.png")+')'
          }
        }

        this.arc_content={
          name:'方案架构',
          img:res.frameworkImg,
          backgroundImg:{},
        }

        this.superiority_content=res.datSolutionAdvs

        this.value_content=res.datSolutionValues


        this.valId=this.value_content[0].id




      });
    },


    setFloorNavMountClick(id){
      this.solutionTabIndex=id
      if(id==='rol0'){
        document.getElementById(id).scrollIntoView(false);
      }else{
        document.getElementById(id).scrollIntoView({
          behavior:'smooth'
        });
      }

    },
  },
}
</script>

<style scoped lang="scss">
.things{
  width: 100%;
  >.things-head{
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover ;
    position: relative;
   /* >:nth-child(1){
      position: absolute;
      top: 99px;
      left: 320px;
      font-size: 48px;
      color: #252B3A;
    }
    >:nth-child(2){
      position: absolute;
      top: 175px;
      left: 320px;
      font-size: 18px;
      color: #252B3A;
    }
    >:nth-child(3){
      position: absolute;
      top: 225px;
      left: 320px;
    }
    .ant-btn::v-deep{
      width: 132px;
      height: 40px;
      font-size: 14px;
    }*/
  }

  >.navigation{
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    height: 71px;
    >a{
     // width: 72px;
      height: 60px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      margin-right: 88px;
      line-height: 60px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      &.active {
        color: #EA0B06;
        border-color: #EA0B06;
      }
    }
  }

  >.box{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    >p{
      margin-top: 50px;
      color: #333333 ;
      font-size: 30px;
    }
  }
  >.introduction{
    height: 590px;
    background-repeat: no-repeat;
    background-size: cover ;
    position: relative;
    >.backgroundInt-con{
      width: 1280px;
      height: 350px;
      background: #FFFFFF;
      display: flex;
      justify-content: flex-start;
      margin-top: 50px;
      margin-bottom: 100px;
      >img{
        display: block;
        margin: 40px;
      }
      >p{
        width: 600px;
        margin-top: 71px;
        font-size: 16px;
        color: #333333;
      }
    }
  }
  >.architecture{
    height: 900px;
    background: #FFFFFF;
    >img{
      width: 1280px;
      height: 710px;
      display: block;
      margin-top: 50px;
    }
  }
  >.superiority{
    padding: 50px 0;
    background: #f7f8fa;
    > div {
      width: 1280px;
      margin: 0 auto;
      > p {
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }

  >.value{
    background: #FFFFFF;


    >.strLayout{
      justify-content: flex-start;
    }

    >.cenLayout{
      justify-content: center;
    }

    >.content{
      width: 1280px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      margin-bottom: 50px;
      >div{
        width: 410px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:flex-start;
        padding:48px 24px ;
        margin-right:24px;
        margin-bottom: 5px;
        &:hover{
          cursor:pointer;
        }
        :nth-child(1){
          font-size: 20px;
          font-weight: 500;
          color: #333333;
        }
        :nth-child(2){
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          word-wrap:break-word;
          margin-top: 24px;
        }
      }
      :nth-child(3n+0){
          margin-right: 0;
      }
      .change:hover{
        box-shadow: 0px 0px 12px rgba(0,0,0,0.1000);
      }
    }
  }

}
</style>
